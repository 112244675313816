<template>
    <div class="custom-card">
      <div class="card-header" :style="{'background-color': color}">
        <slot name="header"></slot>
      </div>
      <div class="card-body">
        <slot name="body"></slot>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    color: String
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  // background-color: #f1f1f1;
}
</style>