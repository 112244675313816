const utils = {}

const ROUND_PRICE_KEY = 10

utils.roundPrice = (price, value) => {
  return Math.round((price * value)/ROUND_PRICE_KEY)*ROUND_PRICE_KEY
}

utils.getInitialPaymentMethod = () => {
  return {
    cash:{
      active: true,
      amount: this.totalAmount 
    },
    card:{
      active: false,
      amount: 0 
    },
    bill:{
      active: false,
      amount: 0 
    },
    account:{
      active: false,
      amount: 0 
    },
  }
};

  utils.compareProviders = (provider1, provider2) => provider1.name > provider2.name

utils.compareCategoryNames = (cat, name) => cat.showName > name

utils.compareFeesNumber = (fee1, fee2) => +(fee1.fee) > +(fee2.fee)

utils.compareCardNames = (card, name) => card.name > name

utils.getInsertionIndex = (arr, item, compareFunction) => {
  let i = 0
  while(i < arr.length && !compareFunction(arr[i], item))
    i++;
  return i;
}

utils.filterCashRegisterStatus = isClosed => {
  return isClosed ? 'Finalizada' : 'Activa'
}

utils.filterOrderStatus = (name) => {
  switch(name){
    case 'active':
      return 'Activo';
    case 'pending':
      return 'Pendiente';
    case 'delayed':
      return 'Demorado';
    case 'cancelled':
      return 'Cancelado';
    case 'refunded':
      return 'Reembolsado';
    case 'finished':
      return 'Finalizado';
  }
}

utils.filterTechnicalServiceStatus = (name) => {
  switch(name){
    case 'entered':
      return 'Ingresado';
    case 'in-workshop':
      return 'En taller';
    case 'budgeted':
      return 'Presupuestado';
    case 'in-repair':
      return 'En reparación';
    case 'retire':
      return 'Para retirar';
    case 'finished':
      return 'Finalizado';
  }
}

utils.filterPaymentMethodName = (name) => {
  switch(name){
    case 'cash':
      return 'Efectivo';
    case 'card':
      return 'Tarjeta';
    case 'bill':
      return 'Cheque';
    case 'account':
      return 'CC';
  }
}

utils.getPrice = (profit, cost, iva) => {
  return (cost * profit * iva).toFixed(3)
}

utils.checkErrors = (primary, secondary, serial) => {
  return (primary?.length > 0 || secondary?.length > 0)
}

utils.getPriceInPesos = (profit, cost, iva, dollar) => {
  const price = (cost * profit * iva).toFixed(3)
  return utils.roundPrice(price, dollar)
}

utils.getProductIndex = (products, id) => {
  const findProduct = product => product._id == id
  return products.findIndex(findProduct)
}

utils.getIvaValue = (ivas, ivaId) => {
  const findIva = iva => iva._id == ivaId
  const index = ivas.findIndex(findIva)
  return ivas[index]?.value
}

utils.getCostInPesos = (cost, iva, dollar) => {
  return utils.roundPrice(cost * iva, dollar)
}

utils.costInDollars = (cost, dollar) => {
  return (cost/dollar).toFixed(3)
}

utils.checkFiles = (files, errors) => {
  let hasErrors = false
  if(files.length > 2){
    errors.push({name: 'Máximo 2 (dos) imagenes'})
    hasErrors = true
  } 
  for(let file of files){
    if(file.size > 100000){
      errors.push({name: 'Las imagenes no pueden superar los 100KB', data: `(Archivo: ${file.name})`})
      hasErrors = true
    }
    if(file.type != 'image/jpeg'){
      errors.push({name: 'Los imagenes deben ser .jpg', data: `(Archivo: ${file.name})`})
      hasErrors = true
    }
  }
  return hasErrors
}

module.exports = utils
